import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Box, Button, Grid, Snackbar, TextField, Theme, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import { copyClipboard } from 'common/Utils/text';
import { TranslationPrefixes } from 'consts/translationPrefixes';
import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClassicEditor from "../../editorBuild/ckeditor";
import CardComponent from "../shared/CardComponent";

const styles = ({ palette }: Theme) => {
	return createStyles({
		container: {
			padding: "40px 15px"
		},
		iconButton: {
			margin: "0 3px",
			width: 35,
			height: 30,
			background: "transparent",
			cursor: "pointer",
			border: 0,
			"&:hover": {
				color: palette.primary.main
			},

		},
		button: {
			marginTop: 15,
		},
		ckeditor: {
			"& .ck.ck-editor": {
				width: "100%"
			},
			"& .ck-editor__editable.ck": {
				minHeight: "169px"
			}
		},
		paper: {
			padding: "15px"
		}
	});
};

function Editor(props: WithStyles<typeof styles>) {
	const [textFieldData, setTextFieldData] = useState<string>("");
	const [openedSnack, setOpenedSnack] = useState(false);
	const inputRef = createRef<CKEditor<ClassicEditor>>();

	const { classes } = props;
	const insertIcon = (icon: string) => {
		const insertPosition = inputRef?.current?.editor?.model.document.selection.getLastPosition() || undefined;
		inputRef?.current?.editor?.model.change(writer => {
			writer.insertText(icon, insertPosition);
		});
	}

	const { t } = useTranslation();
	const { htmlEditor } = TranslationPrefixes;

	const buttonIcons = [
		'✔', '©', '®', '★', '™', '♥', '①', '②', '③', '④'
	];

	const clearText = () => {
		setTextFieldData("");
	}

	const onCopyText = () => {
		copyClipboard(textFieldData).then(() => setOpenedSnack(true));
	}
	const { sideBar } = TranslationPrefixes;
	const [textLength, setTextLength] = useState<number>(0); // State for storing plain text length

	const extractPlainText = (html: string): string => {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || "";
	}
	return (
		<CardComponent title={t(`${sideBar}htmlEditor`)}  >
			<Box>
				<Box mb={1}>
					<Grid container alignItems="center" >
						<Grid item>
							<Typography>{t(`${htmlEditor}symbols`)}</Typography>
						</Grid>
						<Grid item>
							{buttonIcons.map(icon => (
								<button key={icon} className={classes.iconButton} onClick={() => insertIcon(icon)}>{icon}</button>
							))}
						</Grid>
					</Grid>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={6} container className={classes.ckeditor}>
						<CKEditor
							editor={ClassicEditor}
							onChange={(_e, editor) => {
								const htmlData = editor.getData();
								const plainText = extractPlainText(htmlData);
								setTextFieldData(htmlData);
								setTextLength(plainText.length);
							}}
							ref={inputRef}
							data={textFieldData}
						/>
						<Typography style={{ marginTop: 10, width: "100%" }} align="right">{t(`${htmlEditor}symbolsAmount`)} {textLength || 0}</Typography>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							value={textFieldData}
							variant="outlined"
							disabled
							multiline
							minRows={9}
						></TextField>
						<Typography style={{ marginTop: 10, width: "100%" }} align="right">{t(`${htmlEditor}symbolsAmount`)} {textFieldData?.length || 0}</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Button fullWidth variant="contained" color="secondary" className={classes.button} onClick={clearText}>{t(`${htmlEditor}clearText`)}</Button>
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth variant="contained" color="primary" className={classes.button} onClick={() => onCopyText()}>{t(`${htmlEditor}copyText`)}</Button>
					</Grid>
				</Grid>
				<Snackbar
					open={openedSnack}
					autoHideDuration={1000}
					onClose={() => setOpenedSnack(false)}
					message={t(`${htmlEditor}copiedText`)}
				/>
			</Box>
		</CardComponent>
	);

}
export default withStyles(styles, { name: "Editor" })(Editor);