import { Box, Checkbox, Divider, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { getFilteredSearchedData } from "common/Utils/filterSearchedData";
import { FbaFee, InventoryFBA, InventoryReport, Order } from "common/types";
import { InventoryCardHeaderActions } from "components/shared/InventoryCardHeaderActions";
import { TranslationPrefixes } from "consts/translationPrefixes";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getDataDB, getFees, getInventoryListingData, getInventoryUnsupressedData, getOrders, setInventoryReorderDates } from "services/service";
import { getColumns, getDynamicColumnsForExport, } from "../../common/Utils/ActionUtils";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import CardComponent from "../shared/CardComponent";
import { Column } from "../shared/TableComponent";
import { TableInventory } from "./TableInventory";

const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}

export interface InventoryD {
    sku: string;
    availableUnits: number;
    daysSupply: number;
    avgDailySales: number;
    reorderStatus: string;
    reorderDate: string;
    reorderUnits: number;
    inboundUnits: number;
    unfulfillableUnits: number;
    reservedUnits: number;
    value: number;
}

export interface ColumnIventoryOrder extends Column {
    accessor?: keyof InventoryD;
}

function Inventory() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>("");
    const [orders, setOrders] = useState<Order[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    const [inventoryFBA, setInventoryFBA] = useState<InventoryFBA[]>([]);
    const [inventoryListingData, setInventoryListingData] = useState<InventoryReport[]>([]);
    const [uploadedData, setUploadedData] = useState<string[][]>([]);
    const [keyForForceUpdate, setKeyForForceUpdate] = useState<number>(0); // Key for force update
    const [inventoryDates, setInventoryDates] = useState<InventoryReorderDate | null>(null);
    const { sideBar, actions, inventory: inventoryPrefix } = TranslationPrefixes;

    interface InventoryReorderDate {
        [sku: string]: {
            reorderDate: string;
        }
    }
    useEffect(() => {
        setIsLoading(true);

        const fetchData = async () => {
            try {
                const [ordersDB, feesDB, inventoryDB, inventoryListing, inventoryReorderDates] = await Promise.all([
                    getOrders(),
                    getFees(),
                    getInventoryUnsupressedData(),
                    getInventoryListingData(),
                    getDataDB<InventoryReorderDate>("inventory"),
                ]);

                setOrders(ordersDB);
                setFees(feesDB);
                setInventoryFBA(inventoryDB);
                setInventoryListingData(inventoryListing);
                setInventoryDates(inventoryReorderDates);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false); // Ensure loading state is reset in case of an error
            }
        }

        fetchData();

        if (uploadedData?.length > 0) {
            uploadedData.map((item) => {
                setInventoryReorderDates(item[0], "reorderDate", item[5]);
                setInventoryReorderDates(item[0], "reorderUnits", item[6]);
            });
            toast.success(uploadedData.length + " items uploaded successfully");
        }
    }, [keyForForceUpdate]);

    const { t } = useTranslation();

    const fetchData = useCallback(async () => {
        try {
            const [ordersDB, feesDB, inventoryDB, inventoryListing, inventoryReorderDates] = await Promise.all([
                getOrders(),
                getFees(),
                getInventoryUnsupressedData(),
                getInventoryListingData(),
                getDataDB<InventoryReorderDate>("inventory"),
            ]);

            setOrders(ordersDB);
            setFees(feesDB);
            setInventoryFBA(inventoryDB);
            setInventoryListingData(inventoryListing);
            setInventoryDates(inventoryReorderDates);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetchData();

        if (uploadedData?.length > 0) {
            uploadedData.forEach((item) => {
                setInventoryReorderDates(item[0], "reorderDate", item[5]);
            });
            toast.success(uploadedData.length + " items uploaded successfully");
        }
    }, [keyForForceUpdate, fetchData, uploadedData]);

    const calculateSales = useCallback((orders: Order[], sku: string, days: number) => {
        const cutoffDate = new Date();
        cutoffDate.setDate(cutoffDate.getDate() - days);

        return orders.reduce((total, order) => {
            const orderDate = new Date(order['purchase-date']);
            if (orderDate >= cutoffDate && order.sku === sku) {
                return total + order.quantity;
            }
            return total;
        }, 0);
    }, []);

    const tableData = useMemo(() => {
        return inventoryListingData.map(el => {
            const amount = el?.quantity || 0;
            const last7daysSales = calculateSales(orders, el.sku, 7);
            const daysSupply = Math.round(amount / 0.28);
            const inventoryFBAitem = inventoryFBA.find(invEl => invEl.sku === el.sku);
            return {
                ...el,
                sku: el.sku,
                availableUnits: amount,
                daysSupply: daysSupply,
                avgDailySales: last7daysSales,
                reorderStatus: amount < 10 ? t(`${inventoryPrefix}loading`) : t(`${inventoryPrefix}аvailability`),
                reorderDate: inventoryDates?.[el.sku]?.reorderDate || "",
                reorderUnits: 0,
                inboundUnits: inventoryFBAitem?.["afn-inbound-receiving-quantity"] || 0,
                unfulfillableUnits: inventoryFBAitem?.['afn-unsellable-quantity'] || 0,
                reservedUnits: inventoryFBAitem?.['afn-reserved-quantity'] || 0,
            }
        });
    }, [inventoryListingData, orders, inventoryFBA, inventoryDates, calculateSales, t, inventoryPrefix]);

    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: tableData.map(el => el.sku) });

    const createTableCell = useCallback((accessor: keyof InventoryD) => {
        return {
            accessor,
            cell: (val: any) => <Typography variant="body1">{val}</Typography>,
            header: t(`${inventoryPrefix}${accessor}`)
        }
    }, [t, inventoryPrefix]);

    const tableCells: (keyof InventoryD)[] = [
        "sku",
        "availableUnits",
        "daysSupply",
        // "avgDailySales",
        // "reorderStatus",
        // "reorderDate",
        // "reorderUnits",
        "inboundUnits",
        "unfulfillableUnits",
        "reservedUnits"
    ];

    const columns: Array<ColumnIventoryOrder> = useMemo(() => [
        {
            cell: (el) => (
                <Checkbox
                    checked={allSelectedIds.includes(el.sku)}
                    onChange={() => toggleSelect({ singleSelector: el.sku })}
                />
            ),
            header: "checkBox"
        },
        ...tableCells.map(createTableCell)
    ], [allSelectedIds, toggleSelect, createTableCell]);

    const componentRef = useRef<TableComponentToPrint | null>(null);
    const csvColumns = useMemo(() => getDynamicColumnsForExport({ t, prefix: TranslationPrefixes.inventory, values: Object.values(tableCells) }), [t]);

    const renderHeaderActions = useCallback(() => {
        if (!allSelectedIds.length) return;
        const selectedData = tableData.filter((el) => allSelectedIds.includes(el.sku));
        const csvData = getCsvData(selectedData);
        const csvFileName = `inventory_report_${+new Date()}.csv`;
        return (
            <InventoryCardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
                showPrint={true}
                showUploadCsv={false}
                uploadedCSVData={(data) => {
                    setUploadedData(data);
                    setKeyForForceUpdate(prev => prev + 1);
                }}
            />
        );
    }, [allSelectedIds, tableData, csvColumns]);

    const filteredData = useMemo(() => getFilteredSearchedData({ tableData, currentSearch }) as InventoryD[], [tableData, currentSearch]);


    return (
        <CardComponent title={t(`${sideBar}inventory`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                onChange={(e) => setCurrentSearch(e.target.value as string)}
                                label={t(`${actions}search`)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                {tableData.length > 0 && (
                    <div style={{ display: "none" }}>
                        <TableComponentToPrint
                            ref={componentRef}
                            data={tableData}
                            headers={csvColumns}
                            title={t(`${sideBar}inventory`)}
                        />
                    </div>
                )}
                <TableInventory
                    data={filteredData}
                    columns={columns}
                    checkBox={{
                        checked: tableData.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => toggleSelect({ toggle: toggleAll })
                    }}
                />
            </Box>
        </CardComponent>
    )
}

export default Inventory;
