import { Currency } from "interfaces/currency";
import { createContext } from "react";

export interface CurrencyRate {
    value: Currency,
    rate: number
}

export const CurrencyContext = createContext<{
    currency: CurrencyRate, setCurrency: (args: CurrencyRate) => void,
}>({
    currency: {
        rate: 1,
        value: Currency.USD
    },
    setCurrency: (args: CurrencyRate) => { }
});
