import { createTheme } from "@material-ui/core/styles";

const outerTheme = createTheme({
	typography: {
		allVariants: {
			fontFamily: "Tahoma",
			lineHeight: 1.25
		}
	},
	palette: {
		primary: {
			main: "#5c3253"
		},
		secondary: {
			main: "#4b4b4d",
		},
	},
	overrides: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		MuiButton: {

			root: {
				textTransform: "none",

			},
		},
		MuiTab: {
			root: {
				textTransform: "none",
			}
		},
		MuiCard: {
			root: {
				overflow: "initial"
			}
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiTable: {

		}
	}
});

export default outerTheme;
