import { Box, Checkbox, Divider, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { convertCurrency } from "common/Utils/currency";
import { getCogsItems } from "common/Utils/orders";
import { percentage } from "common/Utils/percentage";
import { FbaFee, InventoryReport, ItemCosts } from "common/types";
import { TranslationPrefixes } from "consts/translationPrefixes";
import { CurrencyContext } from "context/currency";
import useToggleSelect from "hooks/useToggleSelect";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { corsSet, getDataDB, getFees, getInventoryListingData } from "services/service";
import { getColumns, getDynamicColumnsForExport } from "../../common/Utils/ActionUtils";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import CardComponent from "../shared/CardComponent";
import { CardHeaderActions } from "../shared/CogsCardHeaderActions";
import TableComponent, { Column } from "../shared/TableComponent";

const getCsvData = (data: any[]) => {
    return data.map(el => {
        Object.keys(el).forEach(key => {
            if (typeof el?.key === "string") {
                el[key] = el[key].split("<br/>").join('\n');
            }
        });
        return el;
    })
}

export enum COGStableDataKeys {
    asin = "asin",
    sku = "sku",
    productPrice = "productPrice",
    fbaFee = "fbaFee",
    exwPrice = "exwPrice",
    customs = "customs",
    transportationCosts = "transportationCosts",
    otherCosts = "otherCosts",
    cogs = "cogs",
    marginPercentage = "marginPercentage",
    marginMoney = "marginMoney",
}

interface ColumnOrders extends Column {
    accessor?: keyof typeof COGStableDataKeys;
}

function Cogs() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState<string>("");
    const [itemCosts, setItemCosts] = useState<ItemCosts>({});
    const [inventory, setInventory] = useState<InventoryReport[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    const [uploadedData, setUploadedData] = useState<string[][]>([]);
    const [keyForForceUpdate, setKeyForForceUpdate] = useState<number>(0); // Key for force update

    const currencyContext = useContext(CurrencyContext);

    const currencyConvert = convertCurrency(currencyContext.currency);
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const [fees, storeItemCosts, fbaUnsuppressedInventory] = await Promise.all(
                    [
                        getFees(),
                        getDataDB<ItemCosts>("itemCosts"),
                        getInventoryListingData()
                    ]);

                setFees(fees);
                setInventory(fbaUnsuppressedInventory)
                setIsLoading(false);
                setItemCosts(storeItemCosts);
            } catch (error) {
                setIsLoading(false); // Ensure loading state is reset in case of an error
            }
        }
        fetchData();

        if (uploadedData?.length > 0) {
            uploadedData.map((item) => {
                corsSet(item[1], "exwPrice", item[4]);
                corsSet(item[1], "customs", item[5]);
                corsSet(item[1], "transportationCosts", item[6]);
                corsSet(item[1], "otherCosts", item[7]);
            });
            toast.success(uploadedData.length + " items uploaded successfully");
        }

        // To do: Upload on Firebase for each item
    }, [keyForForceUpdate]); // Include keyForForceUpdate in the dependency array

    const { t } = useTranslation();

    const cogsItems = getCogsItems({ inventory, fees });
    const tableData = cogsItems.map(({ sku, asin, productPrice, fbaFee }) => {
        let exwPrice;
        let customs;
        let otherCosts;
        let transportationCosts;
        const matchElement = itemCosts?.[sku];

        if (matchElement) {
            exwPrice = matchElement.exwPrice;
            customs = matchElement.customs;
            otherCosts = matchElement.otherCosts;
            transportationCosts = matchElement.transportationCosts;
        }

        const cogs = fbaFee + (exwPrice || 0) + (customs || 0) + (transportationCosts || 0) + (otherCosts || 0);
        return {
            sku,
            asin,
            fbaFee: currencyConvert(fbaFee),
            productPrice: currencyConvert(productPrice),
            customs: customs ? currencyConvert(customs) : undefined,
            exwPrice: exwPrice ? currencyConvert(exwPrice) : undefined,
            marginMoney: (currencyConvert(productPrice - cogs)),
            marginPercentage: percentage(productPrice - cogs, productPrice),
            otherCosts: otherCosts ? currencyConvert(otherCosts) : undefined,
            transportationCosts: transportationCosts ? currencyConvert(transportationCosts) : undefined,
            cogs: cogs ? currencyConvert(cogs) : undefined,
        };
    });

    const { allSelectedIds, toggleSelect } = useToggleSelect({ identificatorSelectors: tableData.map(el => el.sku) });
    const { sideBar, actions, cogs } = TranslationPrefixes;

    const createTableCell = (accessor: COGStableDataKeys, sku: string) => {
        return {
            accessor,
            cell: (val: any) => <Typography variant="body1">{val}</Typography>,
            header: t(`${cogs}${accessor}`)
        }
    }

    const columns: Array<ColumnOrders> = [
        {
            cell: (el) => {
                return (
                    <Checkbox
                        checked={allSelectedIds.includes(el.sku)}
                        onChange={() => toggleSelect({ singleSelector: el.sku })}
                    />

                )
            },
            header: "checkBox"
        },
        ...Object.values(COGStableDataKeys).map(el => createTableCell(el, ""))
    ];

    let componentRef = useRef<TableComponentToPrint | null>(null);
    const csvColumns = getDynamicColumnsForExport({ t, prefix: TranslationPrefixes.cogs, values: COGStableDataKeys });

    const renderHeaderActions = () => {
        if (!allSelectedIds.length) return;
        const selectedData = tableData.filter((el) => allSelectedIds.includes(el.sku));
        const csvData = getCsvData(selectedData);
        const csvFileName = `cogs_${+new Date()}.csv`;
        return (
            <CardHeaderActions
                csvHeaders={getColumns(csvColumns)}
                csvFileName={csvFileName}
                csvData={csvData}
                componentRef={componentRef}
                uploadedCSVData={(data) => { setUploadedData(data); setKeyForForceUpdate(prev => prev + 1); }} // Update keyForForceUpdate
            />
        );
    }

    return (
        <CardComponent key={keyForForceUpdate} title={t(`${sideBar}cogs`)} isLoading={isLoading} headerActions={renderHeaderActions()}>
            <Box>
                <Grid container style={{ flexGrow: 1 }} spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                fullWidth
                                onChange={(e) => {
                                    setCurrentSearch(e.target.value as string);
                                }}
                                label={t(`${actions}search`)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box marginBottom="15px" marginTop="15px">
                    <Divider />
                </Box>
                {
                    tableData.length > 0 && (
                        <div style={{ display: "none" }}>
                            <TableComponentToPrint
                                ref={componentRef}
                                data={tableData}
                                headers={csvColumns}
                                title={t(`${sideBar}cogs`)}
                            />
                        </div>
                    )
                }
                <TableComponent
                    data={tableData}
                    currentSearch={currentSearch}
                    columns={columns}
                    checkBox={{
                        checked: tableData.length === allSelectedIds.length,
                        onChangeCheckBox: (toggleAll) => {
                            toggleSelect({ toggle: toggleAll })
                        }
                    }} />
            </Box>
        </CardComponent>
    )
}

export default Cogs;
