import React, { useState } from "react";
import {
	Typography, createStyles, FormControl, TextField, Box,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import firebase from "../firebase";
import IntroContainer from "../shared/IntroContainer";
import Button from "../shared/Button";


const styles = (theme: Theme) => {
	return createStyles({
		form: {
			width: "100%", // Fix IE 11 issue.
			marginTop: 1,
		},
		link: {
			color: theme.palette.primary.main,
		},
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function Register(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordRepeat, setPasswordPassword] = useState("");
	const [loading, setLoading] = useState(false);

	async function onRegister() {
		if (password.length > 0 && password !== passwordRepeat) {
			// eslint-disable-next-line no-alert
			alert("Passwords do not match!");
		} else {
			setLoading(true);
			try {
				await firebase.register(name, email, password);
				await firebase.addQuote(password);
				props.history.replace("/");
				setLoading(false);
			} catch (error) {
				// eslint-disable-next-line no-alert
				alert(error.message);
				setLoading(false);
			}
		}
	}
	return (
		<IntroContainer>
			<Box marginBottom="10px"><Typography component="h1" variant="h5">Регистрация</Typography></Box>
			<form
				className={classes.form}
				onSubmit={(e) => {
					e.preventDefault();
					return false;
				}}
			>
				<FormControl margin="normal" required fullWidth>
					<TextField variant="outlined" label="Име" id="name" name="name" autoComplete="off" autoFocus value={name} onChange={(e) => setName(e.target.value)} />
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<TextField variant="outlined" label="Имейл адрес" id="email" name="email" autoComplete="off" value={email} onChange={(e) => setEmail(e.target.value)} />
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<TextField variant="outlined" label="Парола" name="password" type="password" id="password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} />
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<TextField variant="outlined" label="Повтори парола" name="quote" type="password" id="quote" autoComplete="off" value={passwordRepeat} onChange={(e) => setPasswordPassword(e.target.value)} />
				</FormControl>
				<Box marginTop="20px">
					<Button
						type="submit"
						fullWidth
						color="primary"
						onClick={onRegister}
						variant="contained"
						size="large"
						disableElevation
						loading={loading}
					>
						Регистрация
					</Button>
				</Box>
				<Box marginTop="20px">
					<Typography>
						{" "}
						<Link to="/login" className={classes.link}>Обратно към Вход</Link>
					</Typography>
				</Box>
			</form>
		</IntroContainer>
	);
}

export default withRouter(withStyles(styles)(Register));
