import { CurrencyRate } from "context/currency";
import { Currency } from "interfaces/currency";


export function convertCurrency(args: CurrencyRate): (valueToConvert: number) => string {
    const { rate, value } = args;

    return (valueToConvert: number = 0) => {
        let convertedValue: number;

        if (value === Currency.USD) {
            convertedValue = valueToConvert;
        } else {
            convertedValue = valueToConvert * rate;
        }

        // Use Intl.NumberFormat to format the number as currency
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: value, // Adjust this if you need to handle other currencies
            minimumFractionDigits: 2,
            maximumFractionDigits: 2

        });

        return formatter.format(convertedValue);
    };
}
