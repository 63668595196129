import {
    Box,
    Button,
    Grid,
    TextField
} from "@material-ui/core";
import UploadIcon from '@material-ui/icons/CloudUpload';
import { TranslationPrefixes } from "consts/translationPrefixes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    RouteComponentProps, withRouter,
} from "react-router-dom";
import OptionCheckbox from "./OptionCheckbox";
import { ManipulationOptions } from "./types";


interface Props extends RouteComponentProps {
    onChangeContaining: (word: string) => void;
    onHandleAllOptionsChange: (event: React.ChangeEvent<HTMLInputElement>, option: keyof ManipulationOptions) => void;
    onConvertTextFieldInput: () => void;
    onHandleTextFieldWords: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onHandleChangeSKU: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
    editModeData?: {
        sku: string;
        phrases: string[];
    };
    isErrorSKU: boolean;
}

function WordsTextField(props: Props): JSX.Element {
    const {
        editModeData,
        children,
        onHandleAllOptionsChange,
        onConvertTextFieldInput,
        onHandleTextFieldWords,
        onHandleChangeSKU,
        isErrorSKU
    } = props;
    const { t } = useTranslation();

    const { keywords } = TranslationPrefixes;

    const [allOptions, setAllOptions] = useState<{ label: string, checked: boolean, option: keyof ManipulationOptions }[]>([
        {
            label: `${keywords}removeContaining`,
            option: "removeContaining",
            checked: false
        },
        {
            label: `${keywords}removeDuplicates`,
            option: "removeDuplicates",
            checked: false
        },
        {
            label: `${keywords}wordsToLowerCase`,
            option: "lowerCase",
            checked: false
        },
        {
            label: `${keywords}wordsToUpperCase`,
            option: "upperCase",
            checked: false
        },
        {
            label: `${keywords}wordsAlphabetically`,
            option: "sortAlphabetically",
            checked: false
        },
        {
            label: `${keywords}singleLine`,
            option: "singleLine",
            checked: false
        },
        {
            label: `${keywords}allUpperCase`,
            option: "allUpperCase",
            checked: false
        }
    ]);

    const handleAllOptionsChange = (event: React.ChangeEvent<HTMLInputElement>, option: keyof ManipulationOptions) => {
        const newOptions = allOptions.map(el => {
            if (el.option === option) {
                return {
                    ...el,
                    checked: event.target.checked
                }
            }

            return el;
        });

        setAllOptions(newOptions);
        onHandleAllOptionsChange(event, option)
    };

    const getDefaultConvertedPhrasesText = () => {
        return editModeData?.phrases.join("\n").toString();
    };

    return <Box  >
        {children}
        <TextField
            fullWidth
            required
            variant="outlined"
            onChange={onHandleTextFieldWords}
            multiline
            label={t(`${keywords}phrasesLabel`)}
            rows={14}
            defaultValue={getDefaultConvertedPhrasesText()}
            InputLabelProps={{
                shrink: !!getDefaultConvertedPhrasesText(),
            }}
        />
        <TextField
            fullWidth
            required
            value={editModeData?.sku}
            label={t(`${keywords}skuLabel`)}
            placeholder={t(`${keywords}skuPlaceholder`)}
            onChange={e => onHandleChangeSKU(e as React.ChangeEvent<HTMLInputElement>)}
            error={isErrorSKU}
            variant="outlined"
            margin="normal"
        />
        <Grid container style={{ width: "100%" }}>

            {allOptions.map(({ label, option, checked }) => (
                <OptionCheckbox
                    key={label}
                    label={label}
                    option={option}
                    checked={!!checked}
                    onChange={(e) => handleAllOptionsChange(e, option)}
                    onChangeContaining={props.onChangeContaining}
                />
            ))}

        </Grid>
        <Button
            variant="contained" color="primary"
            fullWidth
            onClick={onConvertTextFieldInput}
            startIcon={<UploadIcon />}>
            {t(`${keywords}uploadWords`)}
        </Button>
    </Box>

}

export default withRouter(WordsTextField);
