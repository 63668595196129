import {
	Avatar,
	Box,
	Button, createStyles,
	Typography,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Lock from "@material-ui/icons/Lock";
import app from "firebase/app";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import firebase from "../firebase";
import IntroContainer from "../shared/IntroContainer";

const styles = () => {
	return createStyles({
		avatar: {
			margin: "0 auto 10px auto",
			fontSize: 40,
			width: 60,
			height: 60,
		},
	});
};

function Dashboard(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;
	const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);

	useEffect(() => {
		const unsubscribe = app.auth().onAuthStateChanged((user) => {
			if (user) {
				setCurrentUser(user);
				user.reload().then(() => {
					if (user.emailVerified) {
						props.history.replace("/dashboard");
					}
				});
			} else {
				props.history.replace("/login");
			}
		});

		// Cleanup the listener on component unmount
		return () => unsubscribe();
	}, [props.history]);

	async function logout() {
		await firebase.logout();
		props.history.push("/");
	}

	return (
		<>
			{currentUser && (
				<IntroContainer>
					<Avatar className={classes.avatar}>
						<Lock fontSize="inherit" />
					</Avatar>
					<Typography component="h1" variant="h5">
						Hello {currentUser.displayName},
					</Typography>
					<Typography component="h1" variant="body1">
						Please follow the link we've sent to {currentUser.email} in order to verify your account.
					</Typography>
					<Box marginTop="20px">
						<Button
							type="submit"
							fullWidth
							variant="outlined"
							color="secondary"
							onClick={logout}
							className={classes.submit}
							size="large"
							disableElevation
						>
							Logout
						</Button>
					</Box>
				</IntroContainer>
			)}
		</>
	);
}

export default withRouter(withStyles(styles)(Dashboard));
