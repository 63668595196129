import { Box, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    label: string;
    option: string;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>, option: string) => void;
    onChangeContaining: (word: string) => void;
}

const OptionCheckbox = ({ label, option, checked, onChange, onChangeContaining }: Props) => {
    const { t } = useTranslation();

    return <Grid container key={label}>

        <FormControlLabel
            control={
                <Checkbox
                    size='small'
                    checked={!!checked}
                    onChange={(e) => onChange(e, option)}
                />
            }
            label={t(label)}
        />
        <Box sx={{ flex: 1, minWidth: "200px" }}>
            {option === 'removeContaining' && <TextField fullWidth size='small' onChange={(e) => onChangeContaining(e.target.value)} />}
        </Box>
    </Grid>
};

export default OptionCheckbox