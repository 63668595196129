export function containsWord(phrase: string, word: string): boolean {
    const regex = new RegExp(`\\b${word}\\b`, 'i');
    return regex.test(phrase);
}

export function countRepeatingWords(text: string): [string, number][] {
    // Convert text to lowercase and remove punctuation
    const cleanedText = text.toLowerCase().replace(/[.,!?;:()]/g, '');

    // Split the text into an array of words
    const words: string[] = cleanedText.split(/\s+/);

    // Create an object to store word frequencies
    const wordFrequencies: Record<string, number> = {};

    // Count the frequency of each word
    words.forEach((word: string) => {
        if (wordFrequencies[word]) {
            wordFrequencies[word]++;
        } else {
            wordFrequencies[word] = 1;
        }
    });

    // Filter out words that only appear once
    const repeatingWords: Record<string, number> = {};
    for (const word in wordFrequencies) {
        if (wordFrequencies[word] > 1) {
            repeatingWords[word] = wordFrequencies[word];
        }
    }

    return Object.entries(repeatingWords);
}

export function getTotalWords(phrases: string[]) {
    return phrases.join(" ").split(" ").filter(word => word !== "");
}

export function getTotalCharactersLength(phrases: string[]) {
    return phrases.reduce((prev, curr) => prev + curr.split(" ").join("").length, 0)
}

export function getWordsCounted(phrases: string[]) {
    const text = phrases.join(" ").split(" ");
    const map = new Map<string, { counter: number; firstOccurance: boolean }>();

    for (const word of text) {
        if (map.has(word)) {
            const wordsCounted = map.get(word);
            const counter = wordsCounted ? wordsCounted.counter + 1 : 1;
            map.set(word, {
                counter,
                firstOccurance: true,
            });
        } else {
            map.set(word, {
                counter: 1,
                firstOccurance: true,
            });
        }
    }

    return map;
}

export function areMapsEqual(map1: Map<string, number>, map2: Map<string, number>) {
    if (map1.size !== map2.size) {
        return false;
    }

    for (const [key, value] of map1) {
        if (!map2.has(key) || map2.get(key) !== value) {
            return false;
        }
    }

    return true;
}