import React, { useState, useEffect } from "react";
import "./styles.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Login from "../Login";
import Register from "../Register";
import EmailVerification from "../EmailVerification";
import Dashboard from "../Dashboard";
import ResetPassword from "../ResetPassword";
import firebase from "../firebase";
import outerTheme from "../../theme";
import { ToastContainer } from "react-toastify";
import Loader from "../shared/Loader";
import { CurrencyContext, CurrencyRate } from "context/currency";
import { Currency } from "interfaces/currency";

const PrivateRoutes = () => {
	return (
		<Dashboard />
	)
}

const PublicRoutes = () => {
	return (
		<Switch>
			<Switch>
				<Route exact path="/" component={EmailVerification} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/reset-password" component={ResetPassword} />
				<Route exact path="/sales-research" component={Dashboard} />
				<Route exact path="/database" component={Dashboard} />
				<Route path="*">
					<Redirect to={"/login"} />
				</Route>
			</Switch>
		</Switch>
	)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function App() {
	const [firebaseInitialized, setFirebaseInitialized] = useState(false);
	const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
	const [currency, setCurrency] = React.useState<CurrencyRate>({
		rate: 1,
		value: Currency.USD
	})

	useEffect(() => {
		firebase.isInitialized().then(() => {
			if (!firebaseInitialized) setFirebaseInitialized(true);
		});
	}, [firebase.auth.onAuthStateChanged((user) => {
		if (user) {
			setIsUserLoggedIn(!!user)
		} else {
			setIsUserLoggedIn(false);
		}
	})]);

	const routes = () => {
		if (!firebaseInitialized) return null;

		if (isUserLoggedIn) {
			return <PrivateRoutes />
		}

		return <PublicRoutes />
	}


	return firebaseInitialized ? (
		<MuiThemeProvider theme={outerTheme}>
			<CurrencyContext.Provider value={{ currency, setCurrency }}>
				<CssBaseline />
				<ToastContainer />
				<BrowserRouter>
					{
						routes()
					}
				</BrowserRouter>
			</CurrencyContext.Provider >
		</MuiThemeProvider>

	) : <Loader />
}
