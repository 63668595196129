import { Box, Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import firebase, { base } from './firebase';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(1),
    },
}));

const ReportUpdateComponent: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [lastFetch, setLastFetch] = useState<string | null>(null);
    const [credentialsExist, setCredentialsExist] = useState<boolean>(false);
    const [userId, setUserId] = useState<string | undefined>();
    const location = useLocation();
    useEffect(() => {
        setUserId(firebase.getCurrentUser()?.uid);
        // Subscribe to changes in credentials
        const credentialsRef = firebase.db.ref(`${base}/${userId}/amazonCredentials`);
        credentialsRef.on('value', (snapshot: { val: () => any; }) => {
            const credentials = snapshot.val();
            setCredentialsExist(!!credentials); // Set credentialsExist based on whether credentials exist
        });

        // Subscribe to changes in lastFetch
        const lastFetchRef = firebase.db.ref(`${base}/${userId}/lastFetch`);
        lastFetchRef.on('value', (snapshot: { val: () => any; }) => {
            const lastFetchValue = snapshot.val();
            setLastFetch(lastFetchValue); // Update lastFetch state with the value from Firebase
        });

        return () => {
            // Unsubscribe when component unmounts
            credentialsRef.off();
            lastFetchRef.off();
        };
    }, [userId]); // Dependency array ensures useEffect runs only when userId changes
    if (location.pathname === "/dashboard/fetch-reports" || location.pathname === "/credentialsForm") {
        return null; // Render nothing if current route matches hideOnRoute
    }
    return (
        <Paper>
            <Box padding="8px" mt={2}>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12} md={9} style={{ display: "flex" }}>
                        {!credentialsExist ?
                            <ErrorOutlineIcon color="error" className={classes.icon} /> :
                            lastFetch ?
                                <CheckCircleOutlineIcon color="primary" className={classes.icon} /> :
                                <ErrorOutlineIcon color="error" className={classes.icon} />
                        }
                        <Typography>
                            {!credentialsExist ?
                                t('bar.noCredentials') :
                                lastFetch ?
                                    t('bar.lastFetched') + (lastFetch) :
                                    t('bar.noFetched')
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => history.push(!credentialsExist ? '/credentialsForm' : '/dashboard/fetch-reports')}
                            fullWidth
                            size="small"
                            disableElevation
                        >
                            {!credentialsExist ? t('bar.enterCredentials') : t('bar.fetch')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default ReportUpdateComponent;
