import { AppBar, Box, Grid, Tab } from '@material-ui/core';
import { CurrencyContext } from 'context/currency';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACOS, businessEvaluation, calculateTotal, getFilteredOrders, getVisibleOrders } from '../../common/Utils/orders';
import { FbaFee, InventoryReport, ItemCosts, Order, ReturnItem, VisibleOrder, VisibleOrderMetric } from '../../common/types';

export interface ChartData {
  date: string;
  sumOfTotalRevenue: number;
}

interface OrderChartProps {
  startDate: Date;
  endDate: Date;
  orders: Order[];
  inventory: InventoryReport[];
  fees: FbaFee[];
  singleMetric?: VisibleOrderMetric;
  returnedItems?: ReturnItem[];
  itemCosts: ItemCosts;
}

const formatConfig: Record<VisibleOrderMetric | "businessEvaluation" | "acos" | "cogs" | "refunds", 'currency' | 'percentage' | 'number'> = {
  quantity: 'number',
  revenue: 'currency',
  netMargin: 'percentage',
  netProfit: 'currency',
  businessEvaluation: 'currency',
  acos: 'percentage',
  fbaFee: 'currency',
  adsCost: 'currency',
  cogs: 'currency',
  refunds: 'currency'
};

const OrderChart: React.FC<OrderChartProps> = ({ startDate, endDate, orders, fees, singleMetric, inventory, returnedItems, itemCosts }) => {

  const [filteredOrders, setFilteredOrders] = useState<VisibleOrder[]>([]);
  const currencyContext = useContext(CurrencyContext);
  const currency = currencyContext?.currency;
  const [exchangeRate, setExchangeRate] = useState<number>(1);
  const { t } = useTranslation();
  const module = 'modules.orders.';

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setExchangeRate(data.rates.EUR);
      } catch (error) {
        console.error('Failed to fetch exchange rate:', error);
      }
    };
    fetchExchangeRate();
  }, []);

  const [vOrders, setVOrders] = useState<VisibleOrder[]>([]);
  useEffect(() => {
    const newOrders = getVisibleOrders(orders, fees);
    setVOrders(newOrders);
    const newFilteredOrders = getVisibleOrders(getFilteredOrders(orders, startDate, endDate), fees);
    setFilteredOrders(newFilteredOrders);
  }, [startDate, endDate, orders, fees]);



  const formatValue = (value: number, format: 'currency' | 'percentage' | 'number') => {
    if (format === 'percentage') {
      return `${value.toFixed(2)}%`;
    }
    if (format === 'currency') {
      if (currency.value === 'EUR') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'EUR',
        }).format(value * exchangeRate);
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      }
    }
    return value.toString();
  };

  const renderTabLabel = (metric: VisibleOrderMetric) => {

    let total = calculateTotal(filteredOrders, metric);
    if (metric === "refunds") {
      total = filteredOrders.filter((order) => returnedItems?.some((item) => item.sku === order.sku)).reduce((total, order) => total + order.fbaFee, 0);
    }
    if (metric === 'cogs') {
      const totalFees = filteredOrders.reduce((total, order) => total + order.fbaFee, 0);
      const SKUs = filteredOrders.map((order) => order.sku);
      const totalCosts = Object.entries(itemCosts).reduce((total, value) => total +
        (SKUs.includes(value[0]) ? ((value[1]?.customs || 0) + (value[1]?.transportationCosts || 0) + (value[1]?.otherCosts || 0) + (value[1]?.exwPrice || 0)) : 0)
        , 0);
      total = totalFees + totalCosts;
    }
    return (
      <>
        {t(module + metric)} <br /> {formatValue(total, formatConfig[metric])}
      </>
    );
  };

  // Calculate business evaluation
  const businessEval = businessEvaluation(vOrders);

  return (
    <>
      <Box mb={2}>
        <AppBar position="static" color="default" elevation={0}>
          <Grid container
          >
            <Tab style={(singleMetric && singleMetric !== 'quantity') ? { display: 'none' } : undefined} value="quantity" label={renderTabLabel('quantity')} />
            <Tab style={(singleMetric && singleMetric !== 'revenue') ? { display: 'none' } : undefined} value="revenue" label={renderTabLabel('revenue')} />
            <Tab style={(singleMetric && singleMetric !== 'netMargin') ? { display: 'none' } : undefined} value="netMargin" label={renderTabLabel('netMargin')} />
            <Tab style={(singleMetric && singleMetric !== 'netProfit') ? { display: 'none' } : undefined} value="netProfit" label={renderTabLabel('netProfit')} />
            <Tab style={singleMetric ? { display: 'none' } : undefined} value="businessEvaluation" label={<>{t(module + 'businessEvaluation')} <br /> {formatValue(businessEval, 'currency')}</>} />
            <Tab style={singleMetric ? { display: 'none' } : undefined} value="acos" label={<>ACOS <br /> {formatValue(ACOS, 'percentage')}</>} />
            <Tab style={(singleMetric && singleMetric !== 'fbaFee') ? { display: 'none' } : undefined} value="fbaFee" label={renderTabLabel('fbaFee')} />
            <Tab style={(singleMetric && singleMetric !== 'netProfit') ? { display: 'none' } : undefined} value="netProfit" label={renderTabLabel('cogs')} />
            <Tab style={(singleMetric && singleMetric !== 'adsCost') ? { display: 'none' } : undefined} value="adsCost" label={renderTabLabel('adsCost')} />
            <Tab style={(singleMetric && singleMetric !== 'netProfit') ? { display: 'none' } : undefined} value="netProfit" label={renderTabLabel('refunds')} />
          </Grid>
        </AppBar>
      </Box>
    </>
  );
};

export default OrderChart;
