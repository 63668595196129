import { Box } from '@material-ui/core';
import { FbaFee, InventoryReport, ItemCosts, Order, ReturnItem } from 'common/types'; // Adjust as per your actual types
import { TranslationPrefixes } from 'consts/translationPrefixes';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataDB, getFees, getInventoryListingData, getOrders, getReturnReportData } from 'services/service';
import CardComponent from '../shared/CardComponent';
import PeriodSelection from '../shared/PeriodSelection';
import OrderChart from './OrderChart';

function WorkingDashboard() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orders, setOrders] = useState<Order[]>([]);
    const [fees, setFees] = useState<FbaFee[]>([]);
    const [inventory, setInventory] = useState<InventoryReport[]>([]);
    const [returnedItems, setReturnedItems] = useState<ReturnItem[]>([]);
    const [startDate, setStartDate] = useState<Date>(startOfDay(new Date())); // Default to start of today
    const [endDate, setEndDate] = useState<Date>(endOfDay(new Date())); // Default to end of today
    const [itemCosts, setItemCosts] = useState<ItemCosts>({});

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getOrders(), getFees(), getInventoryListingData(), getReturnReportData(), getDataDB<ItemCosts>("itemCosts")])
            .then((val) => {
                setOrders(val[0]);
                setFees(val[1]);
                setInventory(val[2]);
                setReturnedItems(val[3]);
                setItemCosts(val[4]);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, []);

    const { t } = useTranslation();
    const { sideBar } = TranslationPrefixes;

    const handleDatesChange = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    return (
        <CardComponent title={t(`${sideBar}businessReport`)} isLoading={isLoading} chartPrint>
            <Box>
                <PeriodSelection onDatesChange={handleDatesChange} />
                <OrderChart startDate={startDate} endDate={endDate} orders={orders} fees={fees} inventory={inventory} returnedItems={returnedItems} itemCosts={itemCosts} />
            </Box>
        </CardComponent>
    );
}

export default WorkingDashboard;
