import {
	Box,
	FormControl,
	TextField,
	Typography, createStyles,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { useState } from "react";
import { Link, Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { RoutesUrl } from "../../common/consts/Routes";
import firebase from "../firebase";
import Button from "../shared/Button";
import IntroContainer from "../shared/IntroContainer";

const styles = ({ palette }: Theme) => {
	return createStyles({
		form: {
			width: "100%", // Fix IE 11 issue.
		},
		link: {
			color: palette.primary.main,
		},
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function SignIn(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const goToDashboard = () => {
		return (
			<Redirect to={RoutesUrl.dashboard.orders} />
		)
	}

	async function login() {
		setLoading(true);
		try {
			await firebase.login(email, password);

			goToDashboard();
			setLoading(false);
		} catch (error) {
			// eslint-disable-next-line no-alert
			alert((error as any).message);
			setLoading(false);
		}
	}

	return (
		<IntroContainer>
			<Box marginBottom="10px"><Typography component="h1" variant="h5">Вход</Typography></Box>
			<form
				className={classes.form}
				onSubmit={(e) => {
					e.preventDefault();
					return false;
				}}
			>
				<FormControl margin="normal" required fullWidth>
					<TextField variant="outlined" label="Имейл адрес" id="email" name="email" autoComplete="off" autoFocus value={email} onChange={(e) => setEmail(e.target.value)} />
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<TextField variant="outlined" label="Парола" name="password" type="password" id="password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} />
				</FormControl>
				<Box textAlign="right">
					<Typography variant="caption"><Link to="/reset-password" className={classes.link}>Забравена парола?</Link></Typography>
					{" "}
				</Box>
				<Box marginTop="20px">
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={login}
						disableElevation
						size="large"
						loading={loading}
					>
						Вход
					</Button>
				</Box>
				<Box marginTop="20px">
					<Typography>
						Нямате профил?
						{" "}
						<Link to="/register" className={classes.link}>Регистрирайте се</Link>
					</Typography>
				</Box>
			</form>
		</IntroContainer>
	);
}

export default withRouter(withStyles(styles)(SignIn));
