import { Box, Typography, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { ComponentProps } from "react";
import logo from "../../assets/logo.png";
import logos from "../../assets/logos.jpg";
const styles = () => {
	return createStyles({
		"@global": {
			"#root": {
				padding: "1px",
			},
			body: {
				backgroundSize: "cover",
				backgroundPosition: "bottom center",
			},
			"body, html": {
				padding: 0,
				margin: 0,
				height: "100%",
				width: "100%",
			},
		},
		container: {
			width: "800px",
			margin: "40px auto 40px auto",
			textAlign: "center",
			maxWidth: "calc(100% - 40px)",
			padding: "1px 0",
		},
		logo: {
			width: "200px",
			display: "block",
			margin: "0 auto 25px auto",
		},
		logos: {
			width: "100%",
			display: "block",
			margin: "0 auto 25px auto",
		},
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function SignIn(props: ComponentProps<"body"> & WithStyles): JSX.Element {
	const { classes, children } = props;
	return (
		<Box className={classes.container}>
			<img src={logo} alt="logo" className={classes.logo} />
			<Box marginBottom={2}><Typography variant="h6"><strong>Специализиран софтуер за управление, SEO оптимизация и
				създаване на дигитално съдържание за онлайн търговия в Амазон</strong></Typography></Box>
			<Box marginBottom={6}><Typography  >Модел: Специализиран софтуер за управление, SEO оптимизация и създаване на дигитално съдържание за онлайн търговия
				Сериен № 2024-TMMQ-01</Typography></Box>
			<Box sx={{ width: "400px", maxWidth: "100%", marginLeft: "auto", marginRight: "auto", mb: 8 }}>
				{children}
			</Box>
			<Typography align="left" variant="body1" style={{ marginBottom: 15 }}>
				Софтуерът е закупен по АДБФП № BG06RDNP001-19.723-0002-C01 по процедура за предоставяне на безвъзмездна финансова помощ № BG06RDNP001-19.723 по мярка 19-6.4 „Инвестиции в подкрепа на неземеделски дейности“ на Сдружение МИГ „Луковит-Роман“ от Стратегията за Водено от общностите местно развитие, финансирана по ПРСР 2014-2020, съфинансиран от ЕЗФРСР на ЕС.
			</Typography>
			<img src={logos} alt="logos" className={classes.logos} />
			<Typography align="center" variant="body1" style={{ marginBottom: 15 }}>ПРОГРАМА ЗА РАЗВИТИЕ НА СЕЛСКИТЕ РАЙОНИ 2014-2020
			</Typography>
			<Typography align="center" variant="body1" style={{ marginBottom: 15 }}>Европейски земеделски фонд за развитие на селските райони: Европа инвестира в селските райони
			</Typography>

		</Box >
	);
}

export default withStyles(styles)(SignIn);
