import { Button, ButtonGroup } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import TextIcon from "@material-ui/icons/TextFormat";
import Papa from 'papaparse';
import React from "react";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import { TableComponentToPrint } from "../ComponentToPrint/TableComponent";
import CsvIcon from "./CsvIcon";

type Props = {
    csvHeaders: Array<any>;
    csvFileName: string;
    csvData: Array<any>;
    componentRef: React.MutableRefObject<TableComponentToPrint | null>;
    onDownloadTextFile?: () => void;
    uploadedCSVData?: (data: string[][]) => void;
    showPrint?: boolean;
    showUploadCsv?: boolean;
};

export function InventoryCardHeaderActions(props: Props) {
    const { csvHeaders: headers, componentRef, csvData, csvFileName, onDownloadTextFile, uploadedCSVData, showPrint = false, showUploadCsv = true } = props;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Use optional chaining to access files[0]
        if (file) {
            console.log("files", file)
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    const csv = e.target.result.toString();

                    // Using Papa Parse to parse CSV
                    Papa.parse(csv, {
                        complete: (parsed: any) => {
                            // parsed.data contains an array where each element is a row of the CSV
                            const [csvHeaders, ...csvData] = parsed.data as string[][];
                            console.log("Uploaded CSV Headers:", csvHeaders);
                            // Assuming uploadedCSVData is a function to handle parsed CSV data
                            uploadedCSVData && uploadedCSVData(csvData);
                        },
                        header: false, // Disable header auto-detection, assuming first row is headers
                        skipEmptyLines: false, // Skip empty lines
                        dynamicTyping: true,
                    });
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <ButtonGroup variant="contained" color="secondary">
            {showPrint && (
                <Button onClick={() => handlePrint ? handlePrint() : null}>
                    <PrintIcon />
                </Button>
            )}
            {showUploadCsv && (
                <>
                    <label htmlFor="upload-csv">
                        <Button component="span" variant="contained" color="secondary">
                            <CsvIcon></CsvIcon>
                        </Button>
                    </label>
                    <input
                        id="upload-csv"
                        type="file"
                        accept=".csv"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                    />
                </>
            )}
            <Button>
                <CSVLink headers={headers} style={{ color: "inherit", height: "24px" }} data={csvData} filename={csvFileName}>
                    <DescriptionIcon />
                </CSVLink>
            </Button>
            {onDownloadTextFile && (
                <Button onClick={() => onDownloadTextFile()}>
                    <TextIcon />
                </Button>
            )}
        </ButtonGroup>
    );
}
